import { Routes } from '@angular/router';
import { AppRoutes } from './shared/constants';
import { authGuard } from '@shared/guards';

export const routes: Routes = [
	{
		path: AppRoutes.Auth,
		loadChildren: () =>
			import('./layout/auth/auth.routes').then((m) => m.AuthRoutes),
	},
	{
		path: AppRoutes.Empty,
		loadChildren: () =>
			import('./layout/private/private.routes').then((m) => m.PrivateRoutes),
		canMatch: [authGuard],
	},
	{
		path: AppRoutes.Certificado,
		loadChildren: () =>
			import('./layout/certificado/certificado.routes').then(
				(m) => m.CertificadoRoutes
			),
	},
	{
		path: '',
		redirectTo: AppRoutes.Empty,
		pathMatch: 'full',
	},
];
